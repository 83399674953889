import React from 'react';
import Jobs from '../components/Jobs';
import WithTransition from '../components/WithTransition';
import { Layout, SEO } from '../layout';

const JobsPage = () => (
	<Layout>
		<SEO title="Trabalhos" />
		<Jobs />
	</Layout>
);

export default WithTransition(JobsPage);
